/*md

# Paragraph components

```scss_example
.b-cart_empty {
	// ...

	&-paragraph {
		@include t-paragraph_1;
	}
}
```

*/

@mixin t-paragraph_1 {
	font: 400 16px/24px $font-main;
	letter-spacing: -0.2px;
	color: $configuration-t_paragraph_1-color;
	@content;
}

@mixin t-paragraph_2 {
	font: 400 15px/21px $font-main;
	letter-spacing: 0.2px;
	color: $configuration-t_paragraph_2-color;

	@content;
}

@mixin t-paragraph_3 {
	font: 400 13px/19px $font-main;
	letter-spacing: 0.2px;
	color: $configuration-t_paragraph_3-color;

	@content;
}

@mixin t-paragraph_4 {
	font: 400 14px/19px $font-main;
	letter-spacing: 0.2px;
	color: $configuration-t_paragraph_1-color;
	@content;
}
